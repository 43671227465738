import Link from 'next/link'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import Button, { ButtonSize } from '@/components/base/Button'
import Modal from '@/components/base/CustomModal'
import CustomText, { TextSize } from '@/components/base/CustomText'
import { IconsTypes } from '@/components/base/Icons/CustomIcon'
import SunetLogo from '@/components/base/Icons/svg/Sunet'
import SunetIso from '@/components/base/Icons/svg/SunetIso'
import SwitchUser from '@/components/base/Icons/svg/SwitchUser'
import { useAuthStore } from '@/dataStores/auth'
import { useAuthorByUUID } from '@/hooks/useAuthorByUUID'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import useUnreadNotificationsCount from '@/hooks/useUnreadNotificationsCount'

import Avatar from '../../Avatar'
import { SelectAuthorModal } from '../../Post/SelectAuthorModal'
import SearchBarWithOptions from '../RightBar/SearchBarWithOptions'
import LeftBarMenuItem from './LeftBarMenuItem'
import MoreOptionsMenu from './MoreOptionsMenu'

const LeftBarContent = (): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const { author } = useSunetAuthors()

  const cftUrl =
    user?.Companies?.length > 0 ? '/cft/profile-picker' : `/cft/dashboard?AuthorUUID=${user?.UUID}&UserType=user`

  const MenuItems = [
    {
      href: '/',
      label: 'Inicio',
      customIconName: IconsTypes.EcoHouse,
    },
    {
      href: '/notifications',
      label: 'Notificaciones',
      customIconName: IconsTypes.bell,
    },
    {
      href: '/messages',
      label: 'Mensajes',
      customIconName: IconsTypes.Chat,
    },
    {
      href: '',
      label: 'Buscar',
      customIconName: IconsTypes.Search,
    },
    {
      href: '/actions',
      label: 'Acciones',
      customIconName: IconsTypes.Actions,
    },
    {
      href: `/wallet`,
      label: 'Wallet',
      customIconName: IconsTypes.SNT,
    },
    {
      href: cftUrl,
      label: 'Huella C02 y H20',
      customIconName: IconsTypes.Footprint,
      pathname: '/cft',
    },
    {
      href: '/ranking',
      label: 'Ranking',
      customIconName: IconsTypes.Ranking,
    },
    {
      href: '/groups',
      label: 'Grupos',
      customIconName: IconsTypes.community,
    },
    {
      href: '/marketplace',
      label: 'Marketplace',
      customIconName: IconsTypes.Store,
    },
    {
      href: '/ods',
      label: 'ODS',
      customIconName: IconsTypes.ODS,
    },
    {
      href: `/referals`,
      label: 'Referidos',
      customIconName: IconsTypes.Referals,
    },
    {
      href: '/events',
      label: 'Eventos',
      customIconName: IconsTypes.calendar,
    },
    {
      href: `/profile/u/${author?.UUID}/saved`,
      pathname: '/profile/u/[userUUID]/saved',
      label: 'Guardados',
      customIconName: IconsTypes.save,
    },
    {
      href: '/create-organization',
      label: 'Crear organización',
      customIconName: IconsTypes.Mixed,
    },
  ]

  const [open, setOpen] = useState(false)

  const { count } = useUnreadNotificationsCount({ userUUID: author?.UUID })
  const formattedCount = count > 9 ? '+9' : count

  return (
    <div className="w-full z-50 space-y-4 bg-white flex flex-col xl:items-start items-center">
      <Link href="/">
        <div className="flex items-center w-full justify-start pt-4">
          <div className="hidden xl:block ml-16">
            <SunetLogo height={40} />
          </div>
          <div className="xl:hidden">
            <SunetIso width={30} />
          </div>
        </div>
      </Link>
      <ul className="mt-10 w-full">
        <LetftBarProfileLink user={user} />
        {user?.Companies?.length > 0 && <LeftBarSelectUserLink />}
        <LeftBarMenuItem
          iconType={MenuItems[0].customIconName}
          key={MenuItems[0].href}
          exact
          href={MenuItems[0].href}
          label={MenuItems[0].label}
          badgeContent={MenuItems[0].href === '/notifications' && count > 0 ? formattedCount.toString() : undefined}
          item={MenuItems[0]}
        />
        {MenuItems?.map((item) => {
          if (item.label === 'Inicio') {
            return
          }
          const isNotification = item?.href === '/notifications'
          if (item.label === 'Buscar') {
            return (
              <div
                className="flex xl:hidden justify-center items-center p-1 hover:bg-gray-200 rounded-md cursor-pointer"
                key={item.label}
              >
                <Button
                  className="bg-transparent active:bg-transparent"
                  size={ButtonSize.Medium}
                  icon={() => <FiSearch className="text-gray" size={24} />}
                  onClick={() => setOpen(true)}
                />
                <SearchBarWithOptions customSetOpen={setOpen} customOpen={open} showButton={false} />
              </div>
            )
          }

          return (
            <LeftBarMenuItem
              iconType={item.customIconName}
              key={item.href}
              exact
              href={item.href}
              label={item.label}
              badgeContent={isNotification && count > 0 ? formattedCount.toString() : undefined}
              item={item}
            />
          )
        })}
      </ul>
      <hr />

      <MoreOptionsMenu />
    </div>
  )
}

export default LeftBarContent

export const LetftBarProfileLink = ({ user }) => {
  // const active = pathname === `/profile/u/[userUUID]`
  const { author } = useSunetAuthors()

  const { authorData } = useAuthorByUUID({ AuthorUUID: author?.UUID })

  return (
    <li className="flex flex-row items-center justify-start gap-8 h-full">
      <div className="w-4 hidden xl:block" />

      <Link
        href={author?.Type === 'company' ? `/profile/c/${author?.UUID}` : `/profile/u/${user?.UUID}`}
        className="flex m-auto xl:m-0 items-center gap-3 cursor-pointer hover:bg-gray-200 xl:w-full rounded-md p-2"
      >
        <Avatar
          text={`${author?.Name}`}
          className="w-6 h-6 text-white text-sm"
          image={author?.Photo?.Thumbnail}
          levelColor={authorData?.LevelColor}
          borderWidth="sm"
        />
        <CustomText className="text-gray hidden xl:block" size={TextSize.SubTitle2}>
          Mi Perfil
        </CustomText>
      </Link>
    </li>
  )
}

export const LeftBarSelectUserLink = () => {
  const [openSelector, setOpenSelector] = useState(false)

  return (
    <div>
      <div className="flex flex-row items-center justify-start gap-8 h-full">
        <div className="w-4 hidden xl:block" />
        <div
          onClick={() => setOpenSelector(true)}
          className="flex items-center m-auto xl:m-0 gap-3 cursor-pointer hover:bg-gray-200 xl:w-full rounded-md p-2"
        >
          <Button
            className="bg-transparent active:bg-transparent"
            icon={() => <SwitchUser className="text-gray" size={24} />}
          />
          <CustomText className="hidden xl:block text-gray" size={TextSize.SubTitle2}>
            Cambiar usuario
          </CustomText>
        </div>
      </div>
      <Modal
        animate
        open={openSelector}
        onClose={() => {
          setOpenSelector(false)
        }}
        maxWidth={600}
        showCloseButton
        closeOnOutsideClick
      >
        <SelectAuthorModal onSelect={() => setOpenSelector(false)} />
      </Modal>
    </div>
  )
}
