import { signOut } from 'next-auth/react'
import { FiLogOut } from 'react-icons/fi'

import CustomText, { TextSize } from '@/components/base/CustomText'

const MoreOptionsMenu = (): JSX.Element => {
  const handleSignOut = async () => {
    await signOut({
      callbackUrl: '/',
      redirect: true,
    })
  }

  return (
    <div className="xl:pl-11 w-full h-full relative">
      <div className="w-full relative">
        <button className="w-full hover:bg-gray-200 hover:bg-opacity-50 rounded-md p-2" onClick={handleSignOut}>
          <div className="flex flex-row items-center xl:justify-start gap-3">
            <div className="relative m-auto xl:m-0">
              <FiLogOut className="text-gray text-xl" />
            </div>

            <CustomText size={TextSize.SubTitle2} className="text-gray hidden xl:block">
              Cerrar sesión
            </CustomText>
          </div>
        </button>
      </div>
    </div>
  )
}

export default MoreOptionsMenu
